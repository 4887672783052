import { RECAPTCHA_VALIDATE_URL } from 'config/app'

export const validateRecaptcha = async (token, authToken, appCheckToken) =>
  fetch(RECAPTCHA_VALIDATE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken}`,
      // 'X-Firebase-AppCheck': appCheckToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: { token } }),
  })
